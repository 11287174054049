import React from 'react';

import Page from '../shared/Page';
import styled from 'styled-components';
import palette from '../shared/palette';
import { center } from '../shared/mixins';
import {
  PrimaryHeading,
  CenteredParagraphText,
  ParagraphText,
} from '../shared/typography';

const EnrollNowPage = () => {
  return (
    <Page currPage="/enroll">
      <Container>
        <Subheading>18 years or older</Subheading>
        <CenteredParagraphText>
          If you are currently 18 years or older, we need to obtain your consent
          to enroll in this study. Click the link below to access the informed
          consent document. Please read each section, and click on the 'I
          acknowledge I have reviewed this section of the WearDuke study' to
          advance to the next section of the form.
        </CenteredParagraphText>
        <CenterWrapper>
          <a
            href="https://redcap.duke.edu/redcap/surveys/?s=H8PHEH3PW7"
            target="_blank"
          >
            <FormLink>Consent Form</FormLink>
          </a>
        </CenterWrapper>
        <CenteredParagraphText style={{ marginBottom: '5rem' }}>
          Once we receive your consent form, we will confirm receipt by email
          and send further instructions to complete enrollment.
        </CenteredParagraphText>
        <Subheading>Under 18 years old</Subheading>
        <CenteredParagraphText>
          If you are currently 17 years of age or younger, we will need to
          obtain your parent's permission before you can enroll. Please click
          the link below to provide one of your parent's (or legal guardian's)
          e-mail address and cell phone number. We will send them a link to the
          parental permission form.
        </CenteredParagraphText>
        <CenterWrapper>
          <a
            href="https://redcap.duke.edu/redcap/surveys/?s=PJA4MC3HEN"
            target="_blank"
          >
            <FormLink>Parent Contact Form</FormLink>
          </a>
        </CenterWrapper>
        <CenteredParagraphText style={{ marginBottom: '1rem' }}>
          Once we receive your parent's permission form, we will email you the
          link to complete the Student Assent Form.
        </CenteredParagraphText>
        <CenteredParagraphText style={{ marginBottom: '5rem' }}>
          All students under 18 years of age will be required to re-consent to
          the study once they turn 18.
        </CenteredParagraphText>
        <Subheading>For all participants</Subheading>
        <CenteredParagraphText style={{ marginBottom: '4rem' }}>
          All participants will be expected to attend an in-person orientation
          during orientation week. Each participant only needs to attend one
          session (located in GA Down Under).
        </CenteredParagraphText>
        <OrientationText>Aug 24th, 1:00-2:30 pm</OrientationText>
        <OrientationText>Aug 24th, 3:00-4:30 pm</OrientationText>
        <OrientationText>Aug 25th, 1:00-2:30 pm</OrientationText>
        <CenteredParagraphText style={{ marginTop: '4rem' }}>
          You can enroll at any time between now and orientation week. However,
          because of the many events occurring during orientation week, we
          encourage you to enroll early so that you can complete all necessary
          paperwork in time. In addition, if you enroll before August 9th, you
          can specify the watch band color on the wearable order form. Students
          who enroll after that date will not be able to request a specific band
          color.
        </CenteredParagraphText>
      </Container>
    </Page>
  );
};

export default EnrollNowPage;

const Container = styled.div`
  background-color: ${palette.light1};
  margin: 0 auto;
  width: 95%;
  max-width: 105rem;
  padding: 10rem 0rem;
`;

const Title = styled(PrimaryHeading)``;

const Subheading = styled.h3`
  ${center}
  color: ${palette.dark2};
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 2rem;
`;

const CenterWrapper = styled.div`
  text-align: center;
  padding: 3rem 0rem;
`;

const FormLink = styled.button`
  background-color: ${palette.dark2};
  color: white;
  border-radius: 6px;
  padding: 2rem 3rem;
  border: none;
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  transition: 0.3s ease;
  outline: none;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 300;

  :hover {
    transform: scale(1.02);
  }

  :active {
    transform: scale(1);
  }
`;

const OrientationText = styled(ParagraphText)`
  text-align: center;
`;
