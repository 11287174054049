import React from 'react';

import Layout from '../components/layout';
import EnrollNowPage from '../EnrollNowPage/EnrollNowPage';

export default () => (
  <Layout>
    <EnrollNowPage />
  </Layout>
);
